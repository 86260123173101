<template>
  <moe-dialog class="goods-dialog" :show="showDialog" :title="title" width="70%" @close="handleDialogClose()" :closeOnClickModal="false">
    <div class="dialog_content">
      <moe-inquire @search="goodsSearch">
        <el-form-item label="商品编号">
          <el-input :value="goodsParams.id" @input="(value) => goodsParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入商品编号" maxlength="100" clearable />
        </el-form-item>

        <el-form-item label="商品名称">
          <el-input v-model.trim="goodsParams.name" placeholder="输入商品名称" maxlength="100" clearable />
        </el-form-item>
      </moe-inquire>

      <div class="df1 df fdc">
        <moe-table
          v-if="showDialog"
          ref="goodsTableRef"
          :tableRowClassName="tableRowClassName"
          :row-class-name="tableRowClassName"
          url="/shop/goods/listWithActivity"
          :params="goodsParams"
          :numberShow="false"
          rowKey="id"
          :multiple="multiple"
          :defaultSelectIds="defaultSelectIds"
          @selectChange="selectChange">
          <el-table-column :selectable="selectable" type="selection" reserve-selection label="id" width="60" />
          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.id }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价" min-width="150" :formatter="formatterSalePrice" />
          <el-table-column prop="stock" label="库存" min-width="80" />
          <el-table-column label="补充说明" min-width="200" v-if="showActivityRepel">
            <template slot-scope="{ row }" v-if="row.runningActivityId">
              <div>{{ runningActivity(row) }}</div>
              <div>{{ `活动ID: ${row.runningActivityId}` }}</div>
            </template>
          </el-table-column>
        </moe-table>
      </div>
    </div>
    <template slot="footer">
      <el-button @click="handleDialogClose()">取消</el-button>
      <el-button type="primary" @click="handleConfirm()">确定</el-button>
    </template>
  </moe-dialog>
</template>

<script>
export default {
  name: 'goodsDialog',
  model: {
    props: 'value',
    event: 'close',
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '添加商品',
    },
    /** 默认选中的商品id集合 */
    defaultSelectIds: {
      type: Array,
      default: () => [],
    },
    /**
      * 商品参与的活动类型(不传默认为商品级别活动)
      * 可用值:
      * GOODS: 商品折扣
      * SHOP: 店铺折扣
      * BUY_GIFT: 买赠活动
      * GOODS_BADGE: 主图打标
      **/
    type: {
      type: String,
      default: 'GOODS',
    },
    /** 是否显示活动互斥 */
    showActivityRepel: {
      type: Boolean,
      default: false,
    },
    /**
     * 可以忽略的活动
     * FLASH: 限时秒杀
     **/
    activityRepelList: {
      type: Array,
      default: () => [],
    },
    /** 是否可以多选 */
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    showDialog(newValue) {
      if (!newValue) {
        this.selectList.splice(0);
      }
    }
  },
  data() {
    return {
      goodsParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        name: '',
        type: this.type,
      },
      selectList: [],
      typeOptions: {
        BUY_GIFT: '买赠',
        SHOP: '店铺折扣',
        GOODS_BADGE: '主图打标'
      }
    }
  },
  methods: {
    /** 禁用已参加活动的商品 */
    selectable(row) {
      let boolean = true;
      if (row.runningActivityId && this.showActivityRepel) {
        boolean = false;
      }
      if (!this.multiple && this.selectList.length && !this.selectList.filter(({ id }) => row.id === id).length) {
        boolean = false;
      }
      return boolean;
    },
    /** 更改已参加活动的商品的背景颜色 */
    tableRowClassName({ row }) {
      if (row.runningActivityId && this.showActivityRepel) {
        return 'disable-row';
      }
      return '';
    },
    goodsSearch(isSearch) {
      if (!isSearch) {
        this.goodsParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          name: '',
          type: this.type,
        };
      }
      this.$refs['goodsTableRef'].searchData();
    },
    formatterSalePrice({ minPrice, maxPrice }) {
      return `¥ ${minPrice}${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? ` - ¥ ${maxPrice}` : ''}`
    },
    runningActivity({ runningActivityType }) {
      if (runningActivityType) {
        return `已经参加了${this.$moe_format.getRunningActivityType(runningActivityType)}活动`
      } else {
        return `已经参加了${this.typeOptions[this.type]}活动`
      }
    },
    selectChange(list) {
      this.selectList = list;
    },
    handleDialogClose() {
      this.goodsSearch(false);
      this.$emit('cancel');
      this.$emit('update:showDialog', false);
    },
    handleConfirm() {
      if (!this.selectList.length) {
        return this.$moe_msg.warning('请选择商品');
      }
      this.$emit('close', this.selectList);
    },
  }
}
</script>