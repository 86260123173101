<template>
  <moe-page title="新建赠品" class="buy-gift-add">
    <moe-form
      class="default-form"
      ref="buyGiftForm"
      :showBack="false"
      :showClose="true"
      :model="buyGiftParams"
      :rules="rules">
      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">基本信息</div>
        <el-form-item label="活动名称" prop="name" label-width="150px">
          <el-input class="w-300" v-model.trim="buyGiftParams.name" placeholder="请输入活动名称，2到16个文字组合" clearable maxlength="16"></el-input>
          <div class="color-info font-10 lh1 mt-10">活动名称仅用于商家自己管理活动，不会显示给用户</div>
        </el-form-item>

        <el-form-item label="活动时间" prop="startTime" label-width="150px">
          <el-date-picker
            class="w-500"
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([buyGiftParams.startTime, buyGiftParams.endTime] = datetime || ['', ''])" />
        </el-form-item>

        <el-form-item label="活动类型" prop="level" label-width="150px">
          <moe-radio-group :disabled="IS_EDIT" v-model="buyGiftParams.level" :radioBoxList="$moe_data.presaleLevel" @change="changeRaido"></moe-radio-group>
          <div class="color-info font-10 df aic">
            <moe-icon name="reminder" size="20"></moe-icon>
            <div class="ml-5">活动创建后，活动类型将无法修改</div>
          </div>
        </el-form-item>
      </div>

      <div class="fwb mb-20 font-20">活动商品与赠品</div>
      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :stripe="true"
          :numberShow="false"
          :data="buyGiftParams.configList"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加商品"
          rowKey="goodsId">
          <el-form-item prop="configList" slot="tool">
            <div class="df aic">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
              <div class="df aic ml-10">已选<div class="color-primary">{{ `${buyGiftParams.configList.length}个` }}</div>商品</div>
            </div>
          </el-form-item>

          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.id }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价" prop="salePrice" min-width="150">
            <template slot-scope="{ row }">
              {{ `¥ ${row.minPrice}${$moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : ''}` }}
            </template>
          </el-table-column>
          <el-table-column label="库存" prop="stock" width="80" />
          <el-table-column label="赠品" min-width="300">
            <template slot-scope="{ row, $index }">
              <div class="df aic jcc mt-20">
                <el-form-item :prop="`configList.${$index}.giftConfigList`" :rules="rules.giftConfigList">
                  <div class="df aic fww" v-if="buyGiftParams.level === 'GOOD'">
                    <div class="df aic mb-20 mr-20" v-for="(giftItem, giftIndex) in row.giftConfigList" :key="giftIndex">
                      <el-form-item :prop="`configList.${$index}.giftConfigList.${giftIndex}.num`" :rules="rules.num">
                        <div class="df aic jcc">
                          <div class="buy-gift-add__gift__image">
                            <moe-image :src="giftItem.goodsItemCover" width="80px" height="80px" />
                            <div class="buy-gift-add__gift__actions" @click="handleGiftDelete(giftItem, $index, giftIndex)">
                              <i class="buy-gift-add__gift__actions__icon el-icon-delete"></i>
                            </div>
                            <div class="buy-gift-add__gift__readonly" :style="!giftItem.running ?'display: block !important;':''">
                              <div class="buy-gift-add__gift__readonly__text">已失效</div>
                            </div>
                          </div>
                          <div class="df fdc jcfs">
                            <div>赠品件数</div>
                            <el-input-number
                              class="w-100"
                              v-model="giftItem.num"
                              controls-position="right"
                              @blur="() => handleChange('GOOD', $index, giftIndex)"
                              :min="1"
                              :precision="0"
                              :step-strictly="true"
                              :max="giftItem.stockLeft">
                            </el-input-number>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                    <div class="w-100 df aic jcc">
                      <el-button icon="el-icon-plus" type="primary" @click="req_queryShopGoodsById('GOOD', row, $index)" plain size="medium"></el-button>
                    </div>
                  </div>
                  <template v-else-if="buyGiftParams.level === 'SKU'">
                    <el-button
                      @click="req_queryShopGoodsById('SKU', row, $index)"
                      :icon="row.giftConfigList.length?'el-icon-circle-check':'el-icon-thumb'"
                      type="text">
                      {{ row.giftConfigList.length ? '已设置' : '设置规格赠品' }}
                    </el-button>
                  </template>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="{ row, $index }">
              <el-button icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">删除</el-button>
            </template>
          </el-table-column>
        </moe-table>
      </div>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="buyGiftLoad" @click="handleSubmit()">{{ buyGiftLoad ? '保存中' : '提交' }}</el-button>
      </template>
    </moe-form>

    <goodsDialog
      :showActivityRepel="true"
      type="BUY_GIFT"
      :showDialog.sync="showDialog"
      :defaultSelectIds="buyGiftParams.configList && buyGiftParams.configList.length ? buyGiftParams.configList.map(({ id }) => id) : []"
      @close="handleClose">
    </goodsDialog>

    <moe-dialog :show="skuForm.showDialog" title="设置规格赠品" width="70%" @close="handleSkuClose()" :closeOnClickModal="false">
      <div class="dialog_content">
        <div class="df fdc df1">
          <moe-form
            class="default-form"
            ref="skuForm"
            :showBack="false"
            :model="skuForm"
            :rules="rules">
            <div style="height: 500px;min-height: 500px;" class="df fdc">
              <moe-table
                v-if="skuForm.list.length"
                :stripe="true"
                :numberShow="false"
                :data="skuForm.list"
                :mode="false"
                :showPage="false"
                :params="{ pageNum: 1 }"
                rowKey="id"
                :defaultSelectIds="skuForm.defaultSelectIds"
                @selectChange="selectChange">
                <el-table-column type="selection" reserve-selection label="id" width="60" />
                <el-table-column label="编号" prop="id" width="80" />
                <el-table-column label="规格" min-width="150">
                  <template slot-scope="{ row }">
                    <div class="df aic jcc">
                      <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="销售价" prop="salePrice" width="150">
                  <template slot-scope="{ row }">
                    {{ `¥ ${row.salePrice}` }}
                  </template>
                </el-table-column>
                <el-table-column label="库存" prop="stock" width="80" />
                <el-table-column label="赠品" min-width="300">
                  <template slot-scope="{ row, $index }">
                    <div class="df aic jcc mt-20">
                      <el-form-item :prop="`list.${$index}.giftConfigList`" :rules="rules.giftConfigList">
                        <div class="df aic fww">
                          <div class="df aic mb-20 mr-20" v-for="(giftItem, giftIndex) in row.giftConfigList" :key="giftIndex">
                            <el-form-item :prop="`list.${$index}.giftConfigList.${giftIndex}.num`" :rules="rules.num">
                              <div class="df aic jcc">
                                <div class="buy-gift-add__gift__image">
                                  <moe-image :src="giftItem.goodsItemCover" width="80px" height="80px" />
                                  <div class="buy-gift-add__gift__actions" @click="handleGiftDelete(giftItem, $index, giftIndex)">
                                    <i class="buy-gift-add__gift__actions__icon el-icon-delete"></i>
                                  </div>
                                  <div class="buy-gift-add__gift__readonly" :style="!giftItem.running ? 'display: block !important;' : ''">
                                    <div class="buy-gift-add__gift__readonly__text">已失效</div>
                                  </div>
                                </div>
                                <div class="df fdc jcfs">
                                  <div>赠品件数</div>
                                  <el-input-number
                                    class="w-100"
                                    v-model="giftItem.num"
                                    controls-position="right"
                                    @blur="() => handleChange('SKU', $index, giftIndex)"
                                    :min="1"
                                    :precision="0"
                                    :step-strictly="true"
                                    :max="giftItem.stockLeft">
                                  </el-input-number>
                                </div>
                              </div>
                            </el-form-item>
                          </div>
                          <div class="w-100 df aic jcc">
                            <el-button icon="el-icon-plus" type="primary" @click="req_queryShopGoodsById('GOOD_SKU', row, $index)" plain size="medium"></el-button>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
              </moe-table>
            </div>
          </moe-form>
        </div>
      </div>
      <template slot="footer">
        <el-button @click="handleSkuClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>

    <giftGoodsDialog
      :showDialog.sync="form.showDialog"
      :defaultSelectIds="form.defaultSelectIds"
      @close="handleGiftClose"
      @cancel="handleCancel">
    </giftGoodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
import giftGoodsDialog from '@/views/ActivityManage/components/giftGoodsDialog.vue';
export default {
  name: 'ActivityManageBuyGiftAdd',
  components: {
    goodsDialog,
    giftGoodsDialog
  },
  computed: {
    IS_EDIT() {
      if (this.buyGiftParams.id) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    const checkGiftConfigList = (rule, value, callback) => {
      if (!value.length) {
        const { selectList, showDialog, list } = this.skuForm
        if (showDialog) {
          const fullFieldList = rule.fullField.split('.'),
            findIndex = fullFieldList[1];
          if (list.length && selectList.length) {
            const findItem = selectList.find(({ id }) => list[findIndex].id === id);
            if (findItem && !findItem.giftConfigList.length) {
              callback(new Error('请添加赠品'));
            } else {
              callback();
            }
          } else {
            callback(new Error('请添加赠品'));
          }
        } else {
          callback(new Error('请添加赠品'));
        }
      } else {
        callback();
      }
    }
    const checkNum = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入赠品件数'));
      } else {
        // const fullFieldList = rule.fullField.split('.'),
        //   source = fullFieldList[0],
        //   index = fullFieldList[1],
        //   giftIndex = fullFieldList[3];
        // let findItem = null;
        // if (source === 'list') {
        //   findItem = this.skuForm.list[index].giftConfigList[giftIndex];
        // } else {
        //   findItem = this.buyGiftParams.configList[index].giftConfigList[giftIndex];
        // }
        // if (findItem && this.$moe_math.mathGreaterThan(value, findItem.stockLeft)) {
        //   callback(new Error('赠品件数"大于"活动总库存'));
        // } else {
        //   callback();
        // }
        callback();
      }
    }
    const verify = this.$moe_verify.verifyForm;
    return {
      buyGiftParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        level: 'GOOD',
        configList: [],
      },
      datetime: [],
      rules: {
        name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 16位'], '2~16'),
        startTime: verify.isEmpty('请选择活动时间'),
        level: verify.isEmpty('请选择活动类型'),
        configList: verify.isEmpty('请添加活动商品'),
        giftConfigList: [{ required: true, validator: checkGiftConfigList, trigger: ['blur', 'change'] }],
        num: [{ required: true, validator: checkNum, trigger: ['blur', 'change'] }],
      },
      buyGiftLoad: false,
      showDialog: false,
      source: '',
      form: {
        showDialog: false,
        id: '',
        giftConfigList: [],
        defaultSelectIds: [],
      },
      skuForm: {
        showDialog: false,
        id: '',
        goodsItemId: '',
        list: [],
        defaultSelectIds: [],
        selectList: [],
      },
    }
  },
  methods: {
    /** 添加商品 确定 */
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.buyGiftParams.configList.find(({ id, goodsId }) => item.id === id || item.id === goodsId);
        if (!findItem) {
          this.buyGiftParams.configList.push({
            ...item,
            giftConfigList: [],
          })
        }
      })
      this.showDialog = false;
    },
    /** 对部分表单字段进行校验的方法 */
    validateField(findIndex) {
      try {
        this.$refs['buyGiftForm'].validateField(`configList.${findIndex}.giftConfigList`);
      } catch {
        console.log('error');
      }
    },
    /** 选择赠品 确定 */
    handleGiftClose(selectList) {
      switch (this.source) {
        case 'GOOD':
          selectList.forEach((item) => {
            let findItem = this.buyGiftParams.configList.find(({ id }) => this.form.id === id).giftConfigList.find(({ giftId }) => item.id === giftId);
            let findIndex = this.buyGiftParams.configList.findIndex(({ id }) => this.form.id === id);

            if (!findItem) {
              this.buyGiftParams.configList[findIndex].giftConfigList.push({
                ...item,
                num: '',
                giftId: item.id,
                id: '',
                running: true,
              })
            }

            this.validateField(findIndex);
          })

          break;
        case 'SKU':
          var findIndex = this.buyGiftParams.configList.findIndex(({ id }) => this.skuForm.id === id);
          this.buyGiftParams.configList[findIndex].giftConfigList = selectList;
          break;
        case 'GOOD_SKU':
          selectList.forEach((item) => {
            var findItem = this.skuForm.list.find(({ id }) => this.skuForm.goodsItemId === id).giftConfigList.find(({ id }) => item.id === id);
            var findIndex = this.skuForm.list.findIndex(({ id }) => this.skuForm.goodsItemId === id);

            if (!findItem) {
              this.skuForm.list[findIndex].giftConfigList.push({
                ...item,
                giftId: item.id,
                num: '',
                running: true,
              })
            }
            // 选择SKU赠品后 替换来源类型
            this.source = 'SKU';

            try {
              this.$refs['skuForm'].validate();
            } catch {
              console.log('error');
            }
          })
          break;
      }
      this.form.showDialog = false;
    },
    /** 选择赠品 取消 */
    handleCancel() {
      this.source = this.buyGiftParams.level;
      this.form.defaultSelectIds.splice(0);
    },
    /** 删除商品 */
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        this.buyGiftParams.configList.splice(index, 1);
      });
    },
    /** 删除赠品 */
    handleGiftDelete({ name }, index, giftIndex) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        switch (this.buyGiftParams.level) {
          case 'GOOD':
            this.buyGiftParams.configList[index].giftConfigList.splice(giftIndex, 1);
            break;
          case 'SKU':
            this.skuForm.list[index].giftConfigList.splice(giftIndex, 1);
            break;
          // case 'GOOD_SKU':
          //   this.skuForm.list.splice(index, 1);
          //   break;
        }
      });
    },
    selectChange(list) {
      this.skuForm.selectList = list;
      try {
        this.$refs['skuForm'].validate();
      } catch {
        console.log('error');
      }
    },
    /** 赠品数量 失去焦点 */
    handleChange(source, index, giftIndex) {
      switch (source) {
        case 'GOOD':
          if (!this.buyGiftParams.configList[index].giftConfigList[giftIndex].num) {
            this.buyGiftParams.configList[index].giftConfigList[giftIndex].num = 1;
          }
          break;
        case 'SKU':
          if (!this.skuForm.list[index].giftConfigList[giftIndex].num) {
            this.skuForm.list[index].giftConfigList[giftIndex].num = 1;
          }
          break;
      }
    },
    /** 切换活动类型 */
    changeRaido(value) {
      if (this.buyGiftParams.configList.length) {
        this.buyGiftParams.configList.forEach((goodsItem) => {
          let giftConfigList = goodsItem.giftConfigList;
          giftConfigList.splice(0);
          goodsItem.giftConfigList = giftConfigList;
        })

        this.$moe_msg.warning('检测到你切换活动类型 已清除赠品内容');
      }
    },
    /** 设置规格赠品 关闭 */
    handleSkuClose() {
      this.skuForm.showDialog = false;
      this.skuForm.selectList.splice(0);
      this.skuForm.list.splice(0);
      this.skuForm.defaultSelectIds.splice(0);
      this.$refs['skuForm'].clearValidate();
    },
    /** 设置规格赠品 确定 */
    handleConfirm() {
      this.$refs['skuForm'].validate(() => {
        let { selectList } = this.skuForm;
        if (!selectList.length) {
          return this.$moe_msg.warning('请选择规格');
        }
        let findIndex = this.buyGiftParams.configList.findIndex(({ id }) => this.skuForm.id === id);
        this.buyGiftParams.configList[findIndex].giftConfigList = this.$moe_lodash.cloneDeepData(this.skuForm.selectList);
        this.skuForm.selectList.splice(0);

        this.validateField(findIndex);
        this.handleSkuClose();
      })
    },
    /** 提交 */
    handleSubmit() {
      this.$refs['buyGiftForm'].validate(() => {
        let { level, configList } = this.buyGiftParams;
        let params = {
          ...this.buyGiftParams,
          configList: configList.map(({ id, giftConfigList }) => {
            if (level === 'GOOD') {
              return {
                goodsId: id,
                goodsItemId: '',
                giftConfigList: giftConfigList.map(({ giftId, num }) => {
                  return {
                    giftId,
                    num
                  }
                })
              }
            } else if (level === 'SKU') {
              return giftConfigList.map((giftConfigItem) => {
                return {
                  goodsId: id,
                  goodsItemId: giftConfigItem.id,
                  giftConfigList: giftConfigItem.giftConfigList.map(({ giftId, num }) => {
                    return {
                      giftId,
                      num
                    }
                  })
                }
              })
            }
          }).flat()
        }
        if (this.IS_EDIT) {
          this.req_updateBuyGift(params);
        } else {
          this.req_addBuyGift(params);
        }
      })
    },
    /** 创建买赠活动 */
    req_addBuyGift(params) {
      this.$moe_api.BUYGIFT_API.addBuyGift(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新建成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.giftLoad = false;
      });
    },
    /** 修改买赠活动 */
    req_updateBuyGift(params) {
      this.$moe_api.BUYGIFT_API.updateBuyGift(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.giftLoad = false;
      });
    },
    /** 获取买赠活动详情 */
    req_getBuyGiftDetail() {
      if (this.$route.query.id) {
        this.$moe_api.BUYGIFT_API.getBuyGiftDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            const { id, name, startTime, endTime, level, configList } = data.result;
            this.buyGiftParams = {
              id,
              name,
              startTime,
              endTime,
              level,
              configList: []
            };
            this.datetime = [startTime, endTime];
            if (configList.length) {
              this.req_getQueryShopGoodsByIds(configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 根据商品ID获取商品列表 */
    req_getQueryShopGoodsByIds(goodsList) {
      //数组去重
      let unionByList = this.$moe_lodash.unionByList(goodsList, goodsList, 'goodsId');
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: unionByList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          switch (this.buyGiftParams.level) {
            case 'GOOD':
              this.buyGiftParams.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                }
              })
              break;
            case 'SKU':
              this.buyGiftParams.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                let giftConfigList = goodsList.filter(({ goodsId }) => item.goodsId === goodsId).map((giftConfigItem) => {
                  return {
                    ...giftConfigItem,
                    id: giftConfigItem.goodsItemId,
                    giftConfigList: giftConfigItem.giftConfigList.map((giftConfigListItem) => {
                      return {
                        ...giftConfigListItem,
                        id: giftConfigListItem.giftId,
                      }
                    })
                  }
                })
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                  giftConfigList: giftConfigList.length ? giftConfigList : [],
                }
              })
              break;
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 查询商品详情 */
    req_queryShopGoodsById(source, { id }, index) {
      this.source = source;
      const { level } = this.buyGiftParams;
      switch (level) {
        case 'GOOD':
          this.form.id = id;
          var findList = this.buyGiftParams.configList[index].giftConfigList;
          this.form.defaultSelectIds = findList.map(({ giftId }) => giftId);
          this.form.showDialog = true;
          break;
        case 'SKU':
          var goodsId = source === 'GOOD_SKU' ? this.skuForm.id : id;
          this.$moe_api.GOODS_API.queryShopGoodsById({ id: goodsId }).then((data) => {
            if (data.code === 200) {
              if (source === 'GOOD_SKU') {
                this.skuForm.goodsItemId = id;
                this.form.showDialog = true;
              } else {
                this.skuForm.id = id;
                let goodsItem = this.$moe_lodash.cloneDeepData(data.result.goodsItem.filter(({ delFlag }) => delFlag === 0));
                let findList = this.$moe_lodash.cloneDeepData(this.buyGiftParams.configList[index].giftConfigList);
                this.skuForm.defaultSelectIds = findList.map(({ id }) => id);
                this.skuForm.list = goodsItem.map((specItem) => {
                  return {
                    ...specItem,
                    giftConfigList: findList.find(({ id }) => specItem.id === id)?.giftConfigList || [],
                  }
                })
                this.skuForm.selectList = this.$moe_lodash.cloneDeepData(findList);
                this.skuForm.showDialog = true;
              }
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          break;
      }
    },
  },
  mounted() {
    this.req_getBuyGiftDetail();
  },
}
</script>

<style lang="scss" scoped>
.buy-gift-add {
  &__gift {
    &__image {
      position: relative;
      height: 80px;
      margin-right: 5px;
      border-radius: 10px;
      overflow: hidden;
      &:hover {
        .buy-gift-add__gift__actions {
          display: block;
        }
      }
    }
    &__actions, &__readonly {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      display: none;
      z-index: 4;
      &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        color: #fff;
      }
    }
    &__readonly {
      z-index: 3;
      &__text {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>