<template>
  <moe-dialog class="gift-goods-dialog" :show="showDialog" :title="title" width="70%" @close="handleDialogClose()" :closeOnClickModal="false">
    <div class="dialog_content">
      <moe-inquire @search="giftGoodsSearch">
        <el-form-item label="赠品名称">
          <el-input v-model.trim="giftGoodsParams.name" placeholder="输入赠品名称" maxlength="100" clearable />
        </el-form-item>
      </moe-inquire>

      <div class="df1 df fdc">
        <moe-table
          v-if="showDialog"
          ref="giftGoodsTableRef"
          :tableRowClassName="tableRowClassName"
          :row-class-name="tableRowClassName"
          url="/shop/gift/list"
          :params="giftGoodsParams"
          :numberShow="false"
          rowKey="id"
          :multiple="multiple"
          :defaultSelectIds="defaultSelectIds"
          @selectChange="selectChange">
          <el-table-column :selectable="selectable" type="selection" reserve-selection label="id" width="60" />
          <el-table-column label="赠品名称" prop="name" min-width="150" :show-overflow-tooltip="true" />
          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-image :src="row.goodsItemCover" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.goodsName }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.id }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="活动规格" min-width="200">
            <div class="df aic jcc" slot-scope="{ row }">
              <moe-tag class="mr-5" v-for="(item, index) in row.goodsItemName.split(',')" :key="index">{{ item }}</moe-tag>
            </div>
          </el-table-column>

          <el-table-column label="销售价" min-width="200">
            <template slot-scope="{ row }">
              {{ `¥ ${row.goodsItemSalePrice}` }}
            </template>
          </el-table-column>

          <el-table-column label="库存" prop="stockLeft" min-width="80" />
        </moe-table>
      </div>
    </div>
    <template slot="footer">
      <el-button @click="handleDialogClose()">取消</el-button>
      <el-button type="primary" @click="handleConfirm()">确定</el-button>
    </template>
  </moe-dialog>
</template>

<script>
export default {
  name: 'GiftGoodsDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '添加赠品',
    },
    /** 默认选中的id集合 */
    defaultSelectIds: {
      type: Array,
      default: () => [],
    },
    /** 是否可以多选 */
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    showDialog(newValue) {
      if (!newValue) {
        this.selectList.splice(0);
      }
    }
  },
  data() {
    return {
      giftGoodsParams: {
        pageNum: 1,
        pageSize: 10,
        listState: 'PUBLISHED',
        name: '',
      },
      selectList: [],
    }
  },
  methods: {
    /** 禁用已参加活动的商品 */
    selectable(row) {
      let boolean = true;
      if (!this.multiple && this.selectList.length && !this.selectList.filter(({ id }) => row.id === id).length) {
        boolean = false;
      }
      return boolean;
    },
    /** 更改已参加活动的商品的背景颜色 */
    tableRowClassName({ row }) {
      if (this.selectList.length && this.selectList.filter(({ id }) => row.id === id).length) {
        return 'disable-row';
      }
      return '';
    },
    giftGoodsSearch(isSearch) {
      if (!isSearch) {
        this.giftGoodsParams = {
          pageNum: 1,
          pageSize: 10,
          listState: 'PUBLISHED',
          name: '',
        };
      }

      this.$refs['giftGoodsTableRef'].searchData();
    },
    selectChange(list) {
      this.selectList = list;
    },
    handleDialogClose() {
      this.giftGoodsSearch(false);
      this.$emit('cancel');
      this.$emit('update:showDialog', false);
    },
    handleConfirm() {
      if (!this.selectList.length) {
        return this.$moe_msg.warning('请选择赠品!')
      }
      this.$emit('close', this.selectList);
    },
  },
}
</script>

<style></style>